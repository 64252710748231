jQuery(document).ready(function ($) {
    // General fucntion for getting a cookie through javascript, since all are returned as a single string with ```document.cookie```.
    // Used here for retrieving IE notification cookie.
    function getCookie(name) {
        return (name = (document.cookie + ';').match(new RegExp(name + '=.*;'))) && name[0].split(/=|;/)[1];
    }
    // Check if browser is IE based on the userAgent. The specific version is not as relevant. Edge is not seen as IE.
    function isIE() {
        var ua = window.navigator.userAgent;
        var msie = ua.indexOf("MSIE ");
        return msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./);
    }
    // Set cookie to store that user has been notified of their IE usage and has clicked it away.
    function setIENotified() {
        document.cookie = "IENotified=true";
    }
    // Get status of IE notifcation. Returns true if user has already been notified.
    function getIENotified() {
        var notified = getCookie('IENotified');
        return !(notified == null || notified == 'false');
    }
    // Determine whether to show the IE pop up modal.
    if (isIE() && !getIENotified()) {
        $('#IEModal').modal('show');
        $('#IEModal').on('hide.bs.modal', function (e) {
            setIENotified();
        });
    }
    // Comboboxes for filters
    $('.select-projects').select2({
        placeholder: "Select or type...",
        theme: 'bootstrap4',
        closeOnSelect: false,
    });
    $('.select-subprojects').select2({
        placeholder: "Select or type...",
        theme: 'bootstrap4',
        closeOnSelect: false
    });
    // $('.select-organisations').select2({
    //     placeholder: "Select or type...",
    //     theme: 'bootstrap4',
    //     closeOnSelect: false
    // });
    $('.select-contains').select2({
        placeholder: "Select or type...",
        theme: 'bootstrap4',
        closeOnSelect: false
    });

    // Remove widths added by select2 plugin
    $('.select2-container').removeAttr('style');
    $('.select2-search__field').removeAttr('style');
});